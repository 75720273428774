.title-report {
  text-align: center;
  color: gray;
  font-size: 25px;
  margin-bottom: -10px;
}

.title-chart {
  font-size: 15px;
  padding: 0px 0px 0px 80px;
}

.subtitle-chart {
  padding: 0px 0px 0px 80px;
}

.p-chart {
  margin-bottom: -10px;
}

.p-chart-reading {
  margin-bottom: 5px;
}

.hr-chart {
  margin-bottom: 25px;
  margin-top: 25px;
}

hr.myhrline {
  margin-top: 65px;
}

.container-charts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.div-chart {
  width: auto;
  // height: 680px;
  background-color: #ffffff;
  margin-top: 30px;
  padding: 20px;
  // margin-bottom: 30px;
}

.title-chart-reading {
  font-size: 15px;
  padding: 0px 0px 0px 18px;
}

.subtitle-chart-reading {
  padding: 0px 0px 0px 18px;
}

.div-chart-reading {
  width: 400px;
  height: 450px;
  background-color: #ffffff;
  margin-top: 65px;
  padding: 20px;
}

.test-chart {
  width: 650px;
  height: 500px;
}

.small-text {
  font-size: 12px;
}

.smaller-text {
  font-size: 11px;
}

.big-text {
  font-size: 13px;
}

// g {
//   /* theme color variables to use in RGB declarations */
//   --red: 0;
//   --green: 153;
//   --blue: 51;
//   /* the threshold at which colors are considered "light".
//   Range: decimals from 0 to 1, recommended 0.5 - 0.6 */
//   --threshold: 0.5;
// }

// .btn {
//   /* sets the background for the base class */
//   background: rgb(var(--red), var(--green), var(--blue));

//   /* calculates perceived lightness using the sRGB Luma method
//   Luma = (red * 0.2126 + green * 0.7152 + blue * 0.0722) / 255 */
//   --r: calc(var(--red) * 0.2126);
//   --g: calc(var(--green) * 0.7152);
//   --b: calc(var(--blue) * 0.0722);
//   --sum: calc(var(--r) + var(--g) + var(--b));
//   --perceived-lightness: calc(var(--sum) / 255);

//   /* shows either white or black color depending on perceived darkness */
//   color: hsl(
//     0,
//     0%,
//     calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)
//   );
// }
