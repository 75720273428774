@import '../../assets/stylesheets/custom_variables.scss';
.box-line {
  border: 1px solid $light;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: -1px;
  .col-md-4,
  .col-md-2 {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .col-md-3 {
    padding-top: 5px;
    padding-bottom: 5px;
    &:nth-child(2) {
      border-left: 1px solid $light;
      border-right: 1px solid $light;
    }
    &:nth-child(3) {
      border-right: 1px solid $light;
    }
  }
}
.disclaimer {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  color: $gray-600;
}
