@import 'app/assets/stylesheets/custom_variables';
@import '~react-datepicker/dist/react-datepicker.css';

.new-scrollbar ::-webkit-scrollbar {
  width: 9px;
}

.new-scrollbar ::-webkit-scrollbar-track {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.1);
}

.new-scrollbar ::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.2);
}

.new-scrollbar ::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.new-scrollbar ::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}
