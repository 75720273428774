.text_disclaimer_oral_reading {
  font-size: 10px;
}

.inputRow {
  min-height: 100px;
}
.disabledRow {
  background-color: #f5f5f5;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.downloadButton {
  text-align: center;
  display: block;
  font-size: 10px;
  background: white;
  border: 0;
}
