@import './../../assets/stylesheets/custom_variables.scss';
.custom-file-label {
  cursor: pointer;
  &:hover {
    &:after {
      background-color: darken($secondary, 8%) $secondary !important;
    }
  }
  &:after {
    color: $white !important;
    content: 'Examinar' !important;
    background-color: $secondary !important;
    cursor: pointer;
  }
}

.cardImgForm {
  width: 100%;
  height: 200px;
  position: relative;
  margin-bottom: 10px;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 90px;
    // @include diagonal-color(transparent, $white, 135deg, 50%);
  }
  .image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.CircleImgForm {
  width: 200px;
  height: 200px;
  margin: 10px auto;
  border-radius: 50%;
  // background: #fff;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 90px;
    height: 90px;
    // @include diagonal-color(transparent, $white, 135deg, 50%);
  }
  .image-fit {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.custom-area-file {
  min-height: 150px;
  .custom-file-input {
    min-height: 150px;
  }
  .custom-area-file-label {
    border: 1px dashed $dark;
    min-height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: $gray-500;
    flex-direction: column;
    &::after {
      display: none;
    }
  }
}
.dropzone-section {
  border-radius: 10px;
  border: 1px dashed $dark;
  min-height: 150px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: $gray-500;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  &:focus {
    border: 1px dashed $secondary;
    box-shadow: 0 0 0 0.2rem transparentize($primary, 0.75);
    outline: none;
  }
  .dropzone-container {
    width: 100%;
    min-height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus {
      outline: none;
    }
    p {
      text-align: center;
      span {
        font-size: 11px;
      }
    }
  }
  .full-row {
    width: 100%;
  }
}
.dropzone-box {
  position: relative;
  margin-bottom: 30px;
  .box-img {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid $light;
    overflow: hidden;
    width: 100%;
    height: 100%;
    .content {
      width: 100%;
      height: 100%;
      position: absolute;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
      .icon-file-text {
        width: 100%;
        height: 100%;
      }
    }
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    &:hover {
      .hover-dropzone {
        display: flex;
      }
    }
    .hover-dropzone {
      // display: none;
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: transparentize($white, 0.3);
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 10px;
      overflow: hidden;
    }
  }
  p {
    font-size: 9px;
    color: $dark;
    margin-top: 5px;
    word-break: break-all;
  }
}
.fix-d {
  small {
    display: block;
  }
}
.big-svg {
  width: 26px;
  height: 26px;
}
.dropzone-active {
  border-color: #2196f3;
}

.dropzone-reject {
  border-color: #ff1744;
}

.dropzone-disabled {
  border-color: orange;
}
