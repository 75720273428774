@import 'custom_variables';
// TEXT
h1 {
  letter-spacing: 1pt;
}
// BOXES
.flexFullHeight {
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}
// LOGIN
.bannerLogin {
  background: $primary;
  background-image: url('../images/Bg.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.borderInput {
  .form-control {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    border-bottom-width: 2px;
    &:focus {
      box-shadow: none;
    }
  }
}
// SIDENABAR
.sidebar {
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  background: $primary;
}
.sidebar-sticky {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0 -15px;
}
.sideNavbar {
  .nav-logo {
    padding: 0.8rem 1.6rem;
    height: 100px;
    img {
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  .nav,
  .navbar {
    border-bottom: 0;
    // height: 101vh;
    .nav-link {
      color: $white;
      background: lighten($primary, 10%);
      border-radius: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      border-left: 7px solid lighten($primary, 10%);
      // padding: 0.8rem 1.6rem;
      margin: 0.2rem 0;
      font-size: $h3-font-size;
      font-weight: 400;
      width: 100%;
      &.active,
      &:hover,
      &:focus {
        color: $white;
        font-weight: 700;
        border-left: 7px solid lighten($primary, 10%);
        background: transparent;
      }
    }
    .dropdown {
      width: 100%;
      .nav-link {
        position: relative;
        &:after {
          right: 10px;
          position: absolute;
          top: calc(50% - 2px);
        }
      }
      &.show {
        .nav-link {
          font-weight: 700;
          border-left: 7px solid lighten($primary, 10%);
          background: lighten($primary, 2%);
          margin-bottom: 0;
          &.active,
          &:hover,
          &:focus {
            border-left: 7px solid lighten($primary, 10%);
            background: lighten($primary, 2%);
          }
        }
      }
      .dropdown-menu {
        position: relative !important;
        width: 100%;
        transform: none !important;
        background: lighten($primary, 2%);
        border: none;
        margin-bottom: 0.2rem;
        .dropdown-item {
          color: $white;
          font-weight: 400;
          padding-left: 30px;
          white-space: normal;
          &.active,
          &:hover,
          &:focus {
            font-weight: 700;
            background: lighten($primary, 10%);
          }
        }
      }
    }
  }
}
// General
.section {
  padding: 2rem 2rem;
}
.card {
  box-shadow: $box-shadow;
  border: 0 !important;
  h3 {
    color: $light;
  }
  .card-link {
    position: absolute;
    top: 1.25rem;
    right: 1.25rem;
  }
}
.progressbar {
  counter-reset: step;
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 50px;
  margin-top: 50px;
  li {
    list-style-type: none;
    font-size: $font-size-base;
    counter-increment: step;
    text-align: center;
    display: table-cell;
    position: relative;
    color: $dark;
    &:before {
      content: counter(step);
      display: block;
      margin: 0 auto 4px;
      color: $secondary;
      background-color: $white;
      width: 36px;
      height: 36px;
      line-height: 32px;
      text-align: center;
      font-weight: bold;
      border: {
        width: 2px;
        style: solid;
        color: $secondary;
        radius: 50%;
      }
    }
    &:after {
      content: '';
      height: 2px;
      width: 100%;
      background-color: $secondary;
      position: absolute;
      top: 16px;
      left: 50%;
      z-index: -1;
    }
    &:last-child {
      &:after {
        display: none;
      }
    }
    &.active {
      font-weight: 700;
    }
    &.done {
      &:before {
        background-color: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }
  }
}
.tabs-axis {
  &.text {
    a {
      padding: 5px 15px;
    }
  }
  a {
    padding: 0;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    .form-control {
      border: 1px solid transparent !important;
    }
    .input-group-append {
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: white !important;
      svg {
        width: 16px;
        height: 16px;
        opacity: 0.5;
        stroke: $dark;
      }
      &:hover {
        svg {
          stroke: $secondary;
          opacity: 1;
        }
      }
    }
    &.new-btn {
      padding: 7px 10px;
      color: transparentize($dark, 0.5);
      background: #e9e9f0;
      border-bottom: 1px solid #e9e9f0;
      margin-left: 5px;
      svg {
        width: 16px;
        height: 16px;
        opacity: 0.5;
        stroke: $dark;
        margin-left: 8px;
      }
      &:hover {
        background: none;
        border-bottom: 1px solid transparent;
        color: $secondary;
        svg {
          stroke: $secondary;
          opacity: 1;
        }
      }
    }
  }
}
.box {
  padding: 15px;
  margin-top: 10px;
}
.form-control {
  &.secondary {
    &:focus {
      border-color: $secondary !important;
      box-shadow: 0 0 0 0.2rem transparentize($secondary, 0.75);
    }
  }
}
.special-m-t {
  margin-top: 1.45rem !important;
}
.small-svg {
  width: 16px;
  height: 16px;
}
.custom-table {
  header {
    padding: 0;
    // margin: 0 -15px;
    // width: auto;
  }
  .rdt_Table {
    .rdt_TableCell {
      color: $dark;
    }
  }
  .gCjpTO {
    color: $dark;
  }
}
.p-fix {
  margin-top: 2px;
  padding: 3px 4px !important;
}
.errors {
  border-color: #e3614e !important;
}

.warning-password {
  color: #e3614e;
}
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-button {
  display: none;
}

.input-form-control:focus {
  box-shadow: none !important;
}

.title-tutorials {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.button-with-loader {
  display: grid !important;
  grid-template-areas: 'stack';

  .spinner {
    visibility: hidden;
    grid-area: stack;
  }

  .text {
    grid-area: stack;
  }

  &.loading {
    .spinner {
      visibility: visible;
    }
    .text {
      visibility: hidden;
    }
  }
}

.gap-1 {
  gap: 4px;
}

.gap-1-5 {
  gap: 6px;
}

.gap-2 {
  gap: 8px;
}

.visibility-hidden {
  visibility: hidden;
}
