.containerSpinnerLoad {
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.containertext {
  display: flex;
  background-color: #f0f0f0;
  z-index: 10;
  text-align: center;
  align-items: center;
  justify-content: center;
  width: 50%;
  min-height: 120px;
}

.containerSpinnerText {
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 600px;
  flex-direction: column;
}

.no-image {
  background: #e6e6e6;
  color: gray;
  width: 600px;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.noInformation {
  padding: 135px 0;
  font-size: 14px;
}
