body {
}

h3 {
  margin: auto;
  text-align: center;
  padding: 10px;
  color: white;
}

.scores-tables {
  .table {
    background-color: white;
    margin: auto;
    border-collapse: separate;
    display: block;
    overflow-x: scroll;
  }

  .table-bordered {
    border-spacing: 0px !important;
  }

  thead,
  tbody {
    display: inline-block;
    top: 1px;
  }

  thead {
    position: sticky;
    top: -1px;
    z-index: 1030;
  }

  tbody {
    height: 400px;
  }

  th {
    background-color: white;
  }

  td,
  th {
    min-width: 100px;
    max-width: 100px;
    word-wrap: break-word;
  }

  .fixed-important {
    position: sticky;
    width: 5em;
    left: 0;
    top: auto;
    z-index: 999;
  }

  .fixed {
    position: sticky;
    width: 5em;
    left: 0;
    top: auto;
    z-index: 99 !important;
    background-color: white;
  }

  .fixed2 {
    position: sticky;
    width: 5em;
    left: 100px;
    top: auto;
    z-index: 999 !important;
    background-color: white;
  }

  .fixed3 {
    position: sticky;
    width: 5em;
    left: 200px;
    top: auto;
    z-index: 999 !important;
    background-color: white;
  }

  .fixed4 {
    position: sticky;
    width: 5em;
    left: 300px;
    top: auto;
    z-index: 999 !important;
    background-color: white;
  }

  .fixed5 {
    position: sticky;
    width: 5em;
    left: 400px;
    top: auto;
    z-index: 999 !important;
    background-color: white;
  }

  .fixed-right {
    position: sticky;
    width: 5em;
    right: 0;
    top: auto;
    z-index: 999 !important;
    background-color: white;
  }

  .fixed-right2 {
    position: sticky;
    width: 5em;
    right: 100px;
    top: auto;
    z-index: 999;
    background-color: white;
  }

  .fixed-right3 {
    position: sticky;
    width: 5em;
    right: 200px;
    top: auto;
    z-index: 999;
    background-color: white;
  }

  td:not(.fixed) {
    z-index: 0;
  }

  .container {
    // width: 1000px;
    // overflow: hidden !important;
  }

  .container-table {
    width: 100%;
    overflow: hidden !important;
  }

  .z-max {
    z-index: 99999 !important;
  }

  //aca estoy

  table {
    table-layout: fixed;
    width: 100%;
    *margin-left: -100px; /*ie7*/
  }
  td,
  th {
    padding: 10px;
    vertical-align: middle !important;
    text-align: center !important;
    width: 128px;
  }

  .center {
    text-align: center !important;
  }
  th {
    background: #f5f6fa;
    color: #b3b6c2;
    text-align: center !important;
  }

  td > .form-group {
    margin-bottom: 0 !important;
  }

  .choices {
    min-width: 150px;
    flex: 3;
  }
  .short {
    min-width: 100px;
    flex: 1;
  }
  .long {
    min-width: 180px;
    flex: 4;
  }
  .long-reading {
    min-width: 325px !important;
    flex: 4;
  }
  .reading {
    min-width: 325px;
    flex: 4;
  }
  tr {
    display: flex;
    flex: 1;
  }
}

.react-datepicker-popper {
  z-index: 9999999 !important;
}
