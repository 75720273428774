@import '../../../assets/stylesheets/custom_variables.scss';
.short {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important ;
}
.three-state-box {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative !important;
  svg {
    z-index: 2;
    position: relative;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
  }
}
.three-state {
  -webkit-appearance: none;
  width: 26px;
  height: 26px;
  display: block;
  margin: 0;
  cursor: pointer;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 2px solid $input-border-color;
    border-radius: 4px;
    cursor: pointer;
    transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
  }

  &:checked {
    ~ label {
      color: #fff;
    }
    &:before {
      border: 13px solid $success;
    }
  }

  &:not(:checked) {
    ~ label {
      color: #fff;
    }
    &:before {
      border: 13px solid $red;
    }
  }

  &:indeterminate {
    ~ label {
      color: #fff;
    }
    &:before {
      border: 13px solid $info;
    }
  }
  &:focus {
    outline: none;
    &:before {
      box-shadow: 0 0 0 0.2rem rgba(86, 90, 172, 0.25);
    }
  }
  &[disabled] {
    &:hover {
      ~ label {
        color: $light;
      }
      &:before {
        border: 2px solid $input-border-color;
      }
    }
    opacity: 0.2;
    cursor: default;
    ~ label {
      opacity: 0.2;
      cursor: default;
    }
  }
}

.custom-box {
  position: relative;
  width: 26px;
  height: 26px;
  label {
    position: absolute;
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    color: $light;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
  }
  input[type='checkbox'] {
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    display: block;
    margin: 0;
    cursor: pointer;

    &:before {
      content: ' ';
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 2px solid $input-border-color;
      border-radius: 4px;
      cursor: pointer;
      transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    }

    &:checked {
      ~ label {
        color: #fff;
      }
      &:before {
        border: 13px solid $secondary;
      }
    }
    &:hover {
      ~ label {
        color: #fff;
      }
      &:before {
        border: 13px solid transparentize($secondary, 0.6);
      }
    }
    &:focus {
      outline: none;
      &:before {
        box-shadow: 0 0 0 0.2rem rgba(86, 90, 172, 0.25);
      }
    }
    &[disabled] {
      &:hover {
        ~ label {
          color: $light;
        }
        &:before {
          border: 2px solid $input-border-color;
        }
      }
      opacity: 0.2;
      cursor: default;
      ~ label {
        opacity: 0.2;
        cursor: default;
      }
    }
  }
  input[type='radio'] {
    -webkit-appearance: none;
    width: 26px;
    height: 26px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    ~ label {
      display: none;
    }

    &:before {
      content: ' ';
      width: 80%;
      height: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: 2px solid $input-border-color;
      border-radius: 50px;
      cursor: pointer;
      transition: all 250ms cubic-bezier(0.4, 0, 0.23, 1);
    }

    &:checked {
      ~ label {
        display: none;
      }
      &:before {
        border: 11px solid $secondary;
      }

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 4px);
        left: calc(50% - 4px);
        width: 8px;
        border-radius: 50%;
        height: 8px;
        background: #fff;
        cursor: pointer;
      }
    }

    &:focus {
      outline: none;
      &:before {
        box-shadow: 0 0 0 0.2rem rgba(86, 90, 172, 0.25);
      }
    }
    &[disabled] {
      opacity: 0.5;
    }
  }
}
.custom-checkbox {
  label {
    cursor: pointer;
  }
}
.hr-last {
  &:last-of-type {
    hr {
      display: none;
    }
  }
  .row-f {
    padding: 0 20px;
  }
}

.text-muted-indicator {
  font-size: 11px;
  color: #6c757d;
}

.align-col {
  margin-top: 23px;
}
